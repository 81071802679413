export default {
  vi: {
    menu: 'Menu',
    home: 'Trang chủ',
    cart: 'Giỏ Hàng',
    prodCate: 'Danh Mục Sản Phẩm',
    blogTitle: 'Trang Blog của Sunny Garden',
    blogCategoryTitle: 'Trang Danh mục Blog của Sunny Garden',
    contact: 'Liên Hệ',
    headQuarter: 'Trụ Sở Chính',
    nursery: 'Vườn Ươm',
    hQAddress: '8 Đường 22, khu dân cư Ấp 5, xã Phong Phú, huyện Bình Chánh, TP. HCM, Việt Nam',
    nurseryAddress: '1953/5 Tạ Quang Bửu, Phường 6, Quận 8, TP. HCM, Việt Nam',
    contactName: 'Tên',
    phone: 'Điện Thoại',
    contactMessage: 'Tin Nhắn',
    sendMessage: 'Gửi Tin Nhắn',
    contactTitle: 'Đừng chần chừ! Hãy Liên Hệ Ngay Với Chúng Tôi Để Biết Thêm Chi Tiết',
    messgeDesc: 'Bạn có thể gửi tin nhắn để đặt hàng hoặc gủi cho chúng tôi yêu cầu của mình',
    contactErrors: {
      emptyName: 'Vui lòng nhập tên!',
      emptyMessage: 'Vui lóng nhập tin nhắn!',
      invalidEmail: 'Địa chỉ email không chính xác!',
    },
    hotline: 'Hotline đặt hàng (+84)963.601.086',
    orderSuccessTitle: 'Đặt hàng thành công!',
    orderSuccessDesc: 'Cảm ơn bạn đã đặt hàng trên Sunny Garden! Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất để xác nhận đơn hàng.',
    deliveryAddress: 'Địa chỉ nhận hàng',
    chatWithUs: 'Chat với chúng tôi',
    addAddress: 'Thêm địa chỉ',
    editAddress: 'Sửa địa chỉ',
    shippingFee: 'Phí vận chuyển',
    prodInfo: 'Thông Tin Sản Phẩm',
  },
  'en-us': {
    menu: 'Menu',
    home: 'Home',
    cart: 'Shopping Cart',
    prodCate: 'Product Category',
    blogTitle: "Sunny Garden's Blog",
    blogCategoryTitle: "Sunny Garden's Blog Category",
    contact: 'Contact',
    headQuarter: 'Head Quarter',
    nursery: 'Nursery',
    hQAddress: '8 street 22, Residental Area 5, Phong Phú, Bình Chánh dist., Hochiminh City, Vietnam',
    nurseryAddress: '1953/5 Tạ Quang Bửu street, ward 6, District 8, Hochiminh City, Vietnam',
    contactName: 'Your Name',
    phone: 'Phone',
    contactMessage: 'Message',
    sendMessage: 'Send Message',
    contactTitle: "Don't hesitate! Please contact us for more information",
    messgeDesc: 'Please input message for ordering or give us a request',
    contactErrors: {
      emptyName: 'Please input your name!',
      emptyMessage: 'Please input your message!',
      invalidEmail: 'The input is not valid E-mail!',
    },
    hotline: 'Hotline for ordering (+84)913.497.006',
    orderSuccessTitle: 'Placed order successfully!',
    orderSuccessDesc: 'Thanks for your order! We will contact you as soon as possible to process order.',
    deliveryAddress: 'Delivery Address',
    chatWithUs: 'Chat with us',
    addAddress: 'Add address',
    editAddress: 'Edit address',
    shippingFee: 'Shipping Fee',
    prodInfo: 'Product Information',
  },
}
