/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

// import 'antd/dist/antd.css'
import { Affix, Layout, Tooltip } from 'antd';
import React from 'react';
import i18n from '../../templates/i18n';
import FooterComponent from '../Footer';
import HeaderComponent from '../Header';
import './index.scss';

const fbMessengerIcon = require('../../images/fb-messenger.png')

const { Header, Footer, Content } = Layout;

const MainLayout = ({ children, locale = 'vi', location }: IProps) => {
  const chatLink = locale === 'vi' ? 'https://m.me/caycanh.sunnygarden' : 'https://m.me/vietnam.adenium.vn'
  return (
    <Layout className="sg-layout">
      <Header className="sg-header">
        <HeaderComponent siteTitle="Sunny Garden" locale={locale} location={location} />
      </Header>
      <Content>
        <main>{children}</main>
      </Content>
      <Footer className="sg-footer">
        <FooterComponent locale={locale} location={location} />
      </Footer>
      <Affix offsetBottom={10}>
        <Tooltip placement="leftTop" title={i18n[locale].chatWithUs}>
          <a style={{ float: 'right' }} href={chatLink} target="_blank" rel="noopener">
            <img src={fbMessengerIcon} />
          </a>
        </Tooltip>
      </Affix>
    </Layout>
  )
}

interface IProps {
  children: any
  locale?: string
  location: Location
}

export default MainLayout
