module.exports = {
  vi: {
    siteMetadata: {
      url: 'https://sunnygarden.vn',
      title: `Sunny Garden - Hoa Cây Cảnh Shop`,
      description: `Hoa Cây Cảnh Sunny Garden - Sen đá: sen đá nâu, sen đá hoa hồng, sen đá Sài Gòn, sen đá đẹp, sen đá kim cương, sen đá lá thơm, sen đá móng rồng, sen đá phong thủy, sen đá giá sỉ, sen đá giá rẻ, sen đá giá sỉ tphcm, sen đá khổng lồ; Cây cảnh: cây cảnh trong nhà, cây cảnh để bàn, cây cảnh mini, cây cảnh phong thủy, cây cảnh văn phòng, cây cảnh bonsai, cây cảnh ngày tết, cây cảnh đẹp dễ trồng, cây cảnh hợp tuổi`,
      author: `@sunnygarden`,
      keywords: [
        'Hoa Cây Cảnh',
        'hoa cay canh',
        'sunnygarden',
        'sunny garden',
        'bonsai',
        'bon sai',
        'mini bonsai',
        'sen đá',
        'sen da',
        'cây cảnh',
        'cay canh',
        'cây phong thuỷ',
        'cay phong thuy',
        'sứ thái',
        'sứ ghép',
        'su thai',
        'su ghep',
        'sen đá nâu',
        'sen đá hoa hồng',
        'sen đá Sài Gòn',
        'sen đá đẹp',
        'sen đá kim cương',
        'sen đá lá thơm',
        'sen đá móng rồng',
        'sen đá phong thủy',
        'sen đá giá sỉ',
        'sen đá giá rẻ',
        'sen đá giá sỉ tphcm',
        'sen đá khổng lồ',
        'cây cảnh trong nhà',
        'cây cảnh để bàn',
        'cây cảnh mini',
        'cây cảnh phong thủy',
        'cây cảnh văn phòng',
        'cây cảnh bonsai',
        'cây cảnh ngày tết',
        'cây cảnh đẹp dễ trồng',
        'cây cảnh hợp tuổi',
        'sen da nau',
        'sen da hoa hong',
        'sen da Sai Gon',
        'sen da dep',
        'sen da kim cuong',
        'sen da la thom',
        'sen da mong rong',
        'sen da phong thuy',
        'sen da gia si',
        'sen da gia re',
        'sen da gia si tphcm',
        'sen da khong lo',
        'cay canh trong nha',
        'cay canh de ban',
        'cay canh mini',
        'cay canh phong thuy',
        'cay canh van phong',
        'cay canh bonsai',
        'cay canh ngay tet',
        'cay canh dep de trong',
        'cay canh hop tuoi',
      ],
      home: 'Trang chủ',
    },
    default: true,
    locale: 'vi',
    siteLanguage: 'vi',
    ogLang: 'vi_VN',
    moneySign: '₫',
    seeAll: 'Xem Tất Cả',
    details: 'Chi tiết',
    shoppingCart: 'Giỏ Hàng',
    product: 'sản phẩm',
    total: 'Tạm Tính',
    grandTotal: 'Thành tiền',
    placeOrder: 'Tiến hành đặt hàng',
    menu: 'Menu',
    home: 'Trang chủ',
    prodInfo: 'Thông Tin Sản Phẩm',
    cart: 'Giỏ Hàng',
    prodCate: 'Danh Mục Sản Phẩm',
    blogTitle: 'Trang Blog của Sunny Garden',
    blogCategoryTitle: 'Trang Danh mục Blog của Sunny Garden',
    desc404: 'Rất tiếc, trang bạn muốn xem không tồn tại.',
    backHome: 'Quay về trang chủ',
    success: 'Gứi thông tin thành công',
    outofStock: 'Hết Hàng',
    successDesc:
      'Chúng tôi đã nhận được thông tin của bạn và sẽ liên hệ lại với bạn trong thời gian sớm nhất. Xin cảm ơn!',
  },
  'en-us': {
    siteMetadata: {
      url: 'https://sunnygarden.vn',
      title: `Sunny Garden - Flowers, Succulents, Adeniums, Plants Shop`,
      description: `Sunny Garden - Supplying Succulents: brown Succulents, rosy Succulents, Saigon Succulents & Bonsai: indoor bonsai, mini bonsai, Ornamental plants, FengShui Plants`,
      author: `@sunnygarden`,
      keywords: [
        'sunnygarden',
        'sunny garden',
        'succulent',
        'Ornamental plants',
        'FengShui Plants',
        'indoor bonsai',
        'mini bonsai',
      ],
      home: 'Home',
    },
    canonical: 'https://sunnygarden.vn',
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en-us',
    ogLang: 'en_US',
    moneySign: '$',
    seeAll: 'See All',
    details: 'Details',
    shoppingCart: 'Shopping Cart',
    product: 'product(s)',
    total: 'Total',
    grandTotal: 'Grand Total',
    placeOrder: 'Place Order',
    menu: 'Menu',
    home: 'Home',
    prodInfo: 'Product Information',
    cart: 'Shopping Cart',
    prodCate: 'Product Category',
    blogTitle: "Sunny Garden's Blog",
    blogCategoryTitle: "Sunny Garden's Blog Category",
    desc404: 'Sorry, the page you visited does not exist.',
    backHome: 'Back Home',
    success: 'Sent your request successfully',
    outofStock: 'Out Of Stock',
    successDesc: 'We have received your information and we will contact you as soon as possible. Thank so much!',
  },
}
