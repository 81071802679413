import { ShoppingCartOutlined } from '@ant-design/icons';
import { Affix, Badge, Button, Col, Row } from 'antd';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useContext } from 'react';
import gI18n from '../../config/i18n';
import { localizedSlug } from '../../gatsby/NodeHelpers';
import CartContext from '../Context/CartContext';
import Menu from '../Menu';
import Search from '../Search';
import i18n from './i18n';
import './index.scss';


const Header = ({ siteTitle = '', locale = 'vi', location }: IProps) => {
  const { totalItems, totalItemsFee } = useContext(CartContext);
  const { logo } = useStaticQuery(query)

  const cartUri = localizedSlug({ lang: locale, uid: 'cart' })

  function handleOnCartClick() {
    navigate(cartUri)
  }

  const renderCart = () => (
    <Button
      size="large"
      shape="round"
      className="sg-cart-btn"
      type="primary"
      danger={true}
      ghost={true}
      onClick={handleOnCartClick}
    >
      <Row gutter={{ xs: 0, sm: 8 }} justify="center" align="middle">
        <Col flex="auto" className="marginLeft5">
          <ShoppingCartOutlined />
        </Col>
        <Col flex="auto" xs={0} sm={0} md={24} lg={24}>{i18n[locale].cart}</Col>
        <Col flex="auto">
          <Badge count={totalItems.toLocaleString(locale)} />
        </Col>
        <Col flex="auto" xs={0} sm={0} md={24} lg={24}>
          <div className="bill">
            <div>
              <sub>{i18n[locale].total}</sub>
              <span>{gI18n[locale].moneySign + totalItemsFee.toLocaleString(locale)}</span>
            </div>
          </div>
        </Col>
      </Row>
    </Button>
  )

  return (
    <Row className="sg-header-component" justify="center" align="middle">
      <Col flex="250px" className="logo">
        <Link to={localizedSlug({ lang: locale, uid: '' })}><Img fluid={logo.childImageSharp.fluid} /></Link>
      </Col>
      <Col flex="auto">
        <Menu mode="horizontal" locale={locale} location={location} />
        <Row justify="center" align="middle" gutter={8}>
          <Col flex="16 1 auto">
            <Search locale={locale} />
          </Col>
          <Col flex="auto">
            {location.pathname.indexOf(cartUri) === -1 &&
              (totalItems > 0 ?
                <Affix offsetTop={10}>
                  {renderCart()}
                </Affix> :
                renderCart())
            }
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo/logo@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

interface IProps {
  siteTitle: string
  locale?: string
  location: Location
}

export default Header