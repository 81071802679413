export default {
  vi: {
    home: 'Trang chủ',
    shop: 'Gian Hàng',
    aboutUs: 'Về Chúng Tôi',
    contact: 'Liên hệ',
    blog: 'Blog',
  },
  'en-us': {
    home: 'Home',
    shop: 'Shop',
    aboutUs: 'About Us',
    contact: 'Contact',
    blog: 'Blog',
  },
}
