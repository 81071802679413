import React, { useEffect } from 'react'
import './search.scss'
import i18n from './i18n'

interface Props {
  locale: string
}

const SearchSG = ({ locale = 'vi' }: Props) => {
  useEffect(() => {
    (() => {
      const cx = '015630230260540712513:tej6javhmjm';
      const gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      const s = document.getElementsByTagName('script')[0];
      if (s.parentNode) {
        s.parentNode.insertBefore(gcse, s);
      }
    })();

    let counter = 0
    const checkExist = setInterval(() => {
      const searchBox = document.getElementById("gsc-i-id1") as HTMLInputElement
      ++counter
      if (searchBox) {
        searchBox.placeholder = i18n[locale].inputSearch
        clearInterval(checkExist);
      }
      if (counter === 1000) {
        clearInterval(checkExist);
      }
    }, 100);

  }, [])
  return (
    <div className="gcse-search" />
  )
}

export default SearchSG
