export default {
  vi: {
    cart: 'Giỏ Hàng',
    total: 'Tổng cộng',
  },
  'en-us': {
    cart: 'Cart',
    total: 'Total',
  },
}
