import { Col, Divider, Row, Typography as T } from 'antd'
import React from 'react'
import Menu from '../Menu'
import i18n from './i18n'
import './index.scss'

const fbIcon = require('../../images/icons/color/fb@2x.png')
const shoppeeIcon = require('../../images/icons/color/shoppee@2x.png')
const lazadaIcon = require('../../images/icons/color/lazada@2x.png')
const tikiIcon = require('../../images/icons/color/tiki@2x.png')

const codIcon = require('../../images/icons/color/cod@2x.png')
const paypalIcon = require('../../images/icons/color/paypal@2x.png')
const momoIcon = require('../../images/icons/color/momo@2x.png')
const zalopayIcon = require('../../images/icons/color/zalopay@2x.png')
const viettelpayIcon = require('../../images/icons/color/viettelpay@2x.png')
const airpayIcon = require('../../images/icons/color/airpay@2x.png')

interface IProps {
  locale?: string
  location: Location
}

const FooterComponent = ({ locale = 'vi', location }: IProps) => (
  <div className="sg-footer-component">
    <Row>
      <Col flex="auto">
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <Menu mode="vertical" locale={locale} location={location} />
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <div className="contactInfo">
              <T.Title type="secondary" level={4}>
                {i18n[locale].hotline}:
              </T.Title>
              <T.Text className="largeSize" type="danger">
                {i18n[locale].orderPhone}
              </T.Text>
              <i> {i18n[locale].workingTime}</i>
              <T.Title type="secondary" level={4} className="email">
                Email:
              </T.Title>
              <T.Text className="largeSize">
                <a href="mailto:sales@sunnygarden.vn">sales@sunnygarden.vn</a>
              </T.Text>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <T.Title type="secondary" level={4}>
              {i18n[locale].onlineShop}
            </T.Title>
            <ul className="list-icons-footer">
              {locale === 'vi' ?
                <>
                  <li>
                    <a href="https://www.facebook.com/caycanh.sunnygarden/" target="_blank" rel="noopener">
                      <img src={fbIcon} alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://shopee.vn/phuong.nguyen2019" target="_blank" rel="noopener">
                      <img src={shoppeeIcon} alt="Shopee" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.lazada.vn/shop/hoa-cay-canh-sunny-garden" target="_blank" rel="noopener">
                      <img src={lazadaIcon} alt="Lazada " />
                    </a>
                  </li>
                  <li>
                    <a href="https://tiki.vn/cua-hang/hoa-cay-canh-sunny-garden" target="_blank" rel="noopener">
                      <img src={tikiIcon} alt="Tiki" />
                    </a>
                  </li>
                </>
                :
                <>
                  <li>
                    <a href="https://www.facebook.com/vietnam.adenium.vn/" target="_blank" rel="noopener">
                      <img src={fbIcon} alt="Facebook" />
                    </a>
                  </li>
                </>
              }
            </ul>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <T.Title type="secondary" level={4}>
              {i18n[locale].paymentMethod}
            </T.Title>
            <ul className="list-icons-footer">
              <li>
                <img src={codIcon} alt="COD" />
              </li>
              <li>
                <img src={paypalIcon} alt="Paypal" />
              </li>
              <li>
                <img src={momoIcon} alt="Momo" />
              </li>
              <li>
                <img src={zalopayIcon} alt="Zalo" />
              </li>
              <li>
                <img src={viettelpayIcon} alt="Viettel" />
              </li>
              <li>
                <img src={airpayIcon} alt="Airpay" />
              </li>
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>
    <Divider />
    <Row align="middle">
      <Col flex="auto">
        <div className="copyright">
          <T.Text>Copyright© 2017 - Sunny Garden (Vietnam) Co., Ltd.</T.Text>
        </div>
      </Col>
    </Row>
  </div>
)

export default FooterComponent
