export default {
  vi: {
    contact: 'Liên hệ',
    hotline: 'Đường dây nóng',
    workingTime: '(8-21h cả T7, CN)',
    onlineShop: 'Gian hàng Online',
    paymentMethod: 'Phương thức thanh toán',
    orderPhone: '(+84)963.601.086',
  },
  'en-us': {
    contact: 'Contact',
    hotline: 'Hotline',
    workingTime: '(8-21h including Sat & Sun)',
    onlineShop: 'Online Shop',
    paymentMethod: 'Payment Method',
    orderPhone: '(+84)913.497.006'
  },
}
