import { Menu } from 'antd'
import { MenuMode } from 'antd/lib/menu'
import { Link } from 'gatsby'
import React from 'react'
import { localizedSlug } from '../../gatsby/NodeHelpers'
import i18n from './i18n'
import './index.scss'

interface Props {
  mode: MenuMode
  locale?: string
  location: Location
}

const MenuMain = ({ mode, locale = 'vi', location }: Props) => {

  const home = localizedSlug({ lang: locale, uid: '' })
  const shop = localizedSlug({ lang: locale, uid: 'shop' })
  const blog = localizedSlug({ lang: locale, uid: 'blog' })
  const contact = localizedSlug({ lang: locale, uid: 'contact' })

  return (
    <Menu selectedKeys={[location.pathname]} mode={mode} className="sg-menu">
      {mode === 'vertical' && (
        <Menu.Item key={home} className="sg-home-btn">
          <Link to={home}>{i18n[locale].home}</Link>
        </Menu.Item>
      )}
      <Menu.Item key={shop}>
        <Link to={shop}>{i18n[locale].shop}</Link>
      </Menu.Item>
      <Menu.Item key={blog}>
        <Link to={blog}>{i18n[locale].blog}</Link>
      </Menu.Item>
      {mode === 'horizontal' && (
        <Menu.Item key={contact}>
          <Link to={contact}>{i18n[locale].contact}</Link>
        </Menu.Item>
      )}
    </Menu>
  )
}

export default MenuMain
